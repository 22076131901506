.contact-log__content {
	border:1px solid $dark-grey;
	margin:10px;
}

.contact-log__title {
	box-sizing: border-box;
	padding-left:10px;
	flex: 0 1 100%;
}

.contact-log__note {
    margin-left: 10px;
    margin-right: 20px;
}

.contact-log__btn-group {
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.contact-log__event-info {
    width: 50%;
    border:1px solid $dark-grey;
    border-radius: 10px;
    margin:15px;
}
