fieldworkers-list > table > tbody > tr,
fieldworkers-list > table > thead > tr {
    display: flex;
}

fieldworkers-list > table > thead > tr > div {
    text-align: left;
}

fieldworkers-list > table > tbody > tr > td:nth-child(1),
fieldworkers-list > table > thead > tr > th:nth-child(1) {
    flex: 1 1 25%;
}

fieldworkers-list > table > tbody > tr > td:nth-child(2),
fieldworkers-list > table > thead > tr > th:nth-child(2) {
    flex: 1 1 45%;
}

fieldworkers-list > table > tbody > tr > td:nth-child(3),
fieldworkers-list > table > thead > tr > th:nth-child(3) {
    text-align: right;
    flex: 1 1 5%;
}

fieldworkers-list > table > tbody > tr > td:nth-child(4),
fieldworkers-list > table > thead > tr > th:nth-child(4) {
    text-align: right;
    flex: 1 1 5%;
}

fieldworkers-list > table > tbody > tr > td:nth-child(5),
fieldworkers-list > table > thead > tr > th:nth-child(5) {
    text-align: right;
    flex: 1 1 10%;
}

fieldworkers-list > table > tbody > tr > td:nth-child(6),
fieldworkers-list > table > thead > tr > th:nth-child(6) {
    text-align: right;
    flex: 1 1 10%;
}

fieldworkers-list > table > tbody > tr > td:nth-child(7),
fieldworkers-list > table > thead > tr > th:nth-child(7) {
    text-align: right;
    flex: 1 1 10%;
}

fieldworkers-list table .text-left {
    text-align: left;
}

fieldworkers-list  table .text-right {
    text-align: right;
}

//scrollable fieldworkers fix
fieldworkers-list {
    overflow:auto;
    height: 40vh;
}

fieldworkers-list > table > thead{
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 0px;
}
