//============================++  BASE  ++====================================//

assign-workload {
	flex: 1 1 auto;
	display: flex;
}

.assign-workloads {
	border: none;
	width:100%;
	display: flex;
	align-items: stretch;
	flex: 1 1 auto;
}

//THIS WILL ADD A BORDER BETWEEN EACH SECTION WITHIN THE ASSIGN-WORKLOADS COMPONENT
.assign-workloads > div:not(:first-child), rhs-fieldworker-panel > div:not(:first-child) {
	border-left:1px solid $dark-grey;//NOTE Variablize me
}

//==========================++  HEADERS  ++===================================//

.assign-workloads__header {
	display: flex;
	border-bottom: 1px solid $dark-grey;
	background-color: $light-grey;
	min-height: 3em;
	text-align: center;
	color: $darkest-grey;
}

.assign-workloads__header > h2 {
	font-weight: 100;
	align-self: center;
	width: 100%;
	padding: 8px;

	@media (max-width:768px) {
		font-size: 1.5rem;
		padding:0px;
	}
}

//==========================++  COLUMNS  ++===================================//

//ASSIGN WORKLOADS - COLUMN
.assign-workloads__column {
	display: flex;
	flex-direction: column;
	flex: 1 2 auto;

	background-color: $light-grey;
	width: 100%;
}

.assign-workloads__container {
	flex: 1 1 auto;
}

.assign-workloads__container > div {
	flex: 1 1 auto;
	display: flex;
}

.assign-workloads__sub-column {
	align-self: stretch;
}

//==========================++  BITS  ++===================================//

.assign-workloads__workloads__survey-week-select {
	padding: 10px;
	border:none;
	border-bottom: 1px solid $mid-grey;
	height:5em;
}

.assign-workloads__workload-list {
	background-color: $white;
	border:none;
	border-bottom: 1px solid $mid-grey;
	// overflow-y: scroll;
	height: 350px;
}

.assign-workloads__workloads__tmr-select {
	display: flex;
	flex: 0 0 auto;
	flex-direction: column;
	padding:10px;
}

.assign-workloads__households {
	display: flex;
	flex-direction: column;
	width: 100%;

	flex: 1 auto;

	background-color: $light-grey;
}

.assign-workloads__assign-all {
	flex: 1 1 auto;
	width: 100%;
	background-color: $mid-grey;
	padding:10px;
	text-align: center;
	border-bottom: 1px solid $dark-grey;
	height: 4em;
	max-height: 4em;
}

//===========================++  FOOTER  ++===================================//

.assign-workloads__footer {
height:3rem;
background-color:$mid-grey;
border-top:1px solid $dark-grey;
flex:0 0 auto;
}

//===========================++  ICONS   ++===================================//

.assign-households-icon {
	fill: red;
}
