.no-events-label {
    justify-content: center;
    text-align: center;
}

.contact-item-content {
    width: 250%;
}

.linked-events-info {
	display: flex;
	flex: 0 1 100%;
	flex-direction: row;
	flex-wrap: wrap;
}

.resolved-label {
    font-weight: bold;
    color: green;
}

.unresolved-label {
    font-weight: bold;
    color: darkgoldenrod;
}

.event-btn-group-grid {
    width: 80%;
    display: grid;
    padding-top: 28px !important;
    grid-column-gap: 2px;
    grid-template-columns: repeat(3, minmax(6em, 1fr));
    align-items: start;
    grid-template-areas: "resolveBtn editBtn deleteBtn";
    .disabled-btn-msg-grid {
        grid-column-start: 1;
        grid-column-end: end;
        grid-row-start: 2;

        display: grid;
        padding: 10px;
        margin-top: 10px;
        grid-template-columns: 20px minmax(6em, 1fr);
        grid-column-gap: 5px;
        align-items: stretch;
        grid-template-areas: "tmr-icon disabled-btn-msg";
    }
}


.indent-left {
    margin-left: 20px;
}

.contact-event-row {
    transition: linear all;
}

.contact-event-row.highlight-on,
.linked-events-info.highlight-on {
    background:$light-blue;
}

i.event-warning-icon {
  color: red;
}

td.contact-item-color-label {
    width: 1em;

    // The idea is that each event gets given the type as a class, and then
    // any other relevant information gets added as a class.
    // Then we can just color-code them here. The red/green/both above shows
    // how we can assign colors at different granularities.
    &.contact {
      // type: validation recruitment follow-up interview
      // outcome: invalidated validated no-response left-pack web refused
      //          refused needs-follow-up exhausted abandoned completed
      //          refer-to-manager scheduled
    }

    &.scheduled-event {
      // type: capi cati follow-up
      // (maybe one of:) created-by-validation created-by-recruitment
      //                 created-by-follow-up created-by-interview
      // resolved: resolved (and one of:) resolved-by-validation
      //           resolved-by-recruitment resolved-by-follow-up
      //           resolved-by-interview
      // unresolved: unresolved (and maybe one of:) due overdue
    }

    $base-sat: 80%;
    $base-lit: 35%; // changed from 50 for accessibility

    &.contact.validation { // A - red
      background: hsl(0, $base-sat, $base-lit);
    }

    &.contact.recruitment { // B1 - orange
      background: hsl(30, $base-sat, $base-lit);
    }

    &.scheduled-event.created-by-recruitment { // B2
      background: hsl(30, $base-sat + 20%, $base-lit + 30%);
    }

    &.contact.follow-up { // C1 - blue
      background: hsl(220, $base-sat, $base-lit);
    }

    &.scheduled-event.created-by-follow-up { // C2
      background: hsl(220, $base-sat + 20%, $base-lit + 30%);
    }

    &.contact.interview { // D1 - green
      background: hsl(100, $base-sat, $base-lit);
    }

    &.scheduled-event.created-by-interview { // D2
      background: hsl(100, $base-sat + 20%, $base-lit + 30%);
    }
}

.non-interesting-event {
    background-color: #dbdada;
}

.non-interesting-event a {
  color: #296394;
}

.non-interesting-event .resolved-label {
  color: green;
}

.separator {
  font-weight: bold;
  text-align: center;
}
