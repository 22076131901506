.alert {
  padding-bottom:10px;
  border-radius: 5px;
}

#display-alerts {
	position: fixed;
	top: 10px;
	right: 10px;
	width: 25%;
	z-index: 100;
}
