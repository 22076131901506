.pending-invite {
    .table {
        .action-column {
            display: flex;
            flex-direction: row;

            button {
                &:last-child {
                    margin-left: 8px;
                }
            }
        }
    }

    .modal-header {
        display: flex;
        .modal-title {
            flex: 1;
            font-size: 1.2em;
            font-weight: 600;
            text-align: center;
        }
    }

    .alert-form-error {
        margin: auto;
    }
}
