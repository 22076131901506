access-household {
	flex:1 1 100%;
	display: flex;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;
}
div.access-household-directly {
	display: flex;
	label {
		margin: 5px;
	}
}

div.access-household-directly > button {
	margin-left: 8px;
	width:50px;
}

div.access-household-directly > input {
	width:12rem;
}

/*
.access-household-directly {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;

	flex: 3 1 auto;

	padding: 10px;
	border: 1px solid $light-grey;
	background-color: $light-grey;
	border-radius: 10px;
	margin: 10px;
}

//Targets items inside .access-household-directly

.access-household-directly > label {
	display: flex;
	padding: 10px;
	margin: 10px;
}

.access-household-directly > input {
	padding: 10px;
	margin: 10px;
	height:34px;
}

.access-household-directly > button {
	margin: 10px;
	padding:10px;
	border-radius: 10px;
	border: 1px solid $mid-grey;
	background-color: $light-grey;
	width: 12rem;
}
*/
