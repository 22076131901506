.form__container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding:10px;
}

.form__half {
	box-sizing: border-box;
	padding:10px;
	flex: 1 0 50%;
}

.form__quarter {
	box-sizing: border-box;
	padding:10px;
	flex: 0 0 25%;
}

.form__third {
	box-sizing: border-box;
	padding:10px;
	flex: 0 0 33.33%;
}

.form__twothird {
	box-sizing: border-box;
	padding:10px;
	flex: 0 0 66.67%;
}

.form__threequarter {
	box-sizing: border-box;
	padding:10px;
	flex: 0 0 75%;
}

.form__40percent {
	box-sizing: border-box;
	padding:10px;
	flex: 0 0 40%;
}

.form__60percent {
	box-sizing: border-box;
	padding:10px;
	flex: 0 0 60%;
}

.form__full {
	box-sizing: border-box;
	padding:10px;
	flex: 0 1 100%;
}
.form__token {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.form__token readonly-field {
	flex: 1 auto;
}

.form__token button {
	flex: 0;
}

.form__notes {
	display: flex;
}

.form__notes-label {
    margin-left: 10px;
}

.form__notes > input {
	flex: 1 1 auto;
	margin-left: 10px;
	margin-right:10px;
}

.form__notes > button {
	flex: 1 1 auto;
  height: 3.4rem;
}

.form__token readonly-field > label {
	flex: 1 100%;
}

.form--right {
	text-align: right;
}

.form--btn--left {
	float: left;
}
