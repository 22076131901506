login {
    flex: 1 1 auto;
    display: flex;
}

.login__container {
    flex:1 1 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 2;
}

.login__box {
    @extend .panel;
    @extend .panel-default;
    flex: 0 0 20%;
    align-self: center;
    min-width: 25em;
}

.login__box__heading {
    @extend .panel-heading;
}

.login__box__body {
    @extend .panel-body;
}

.login__box__footer {
    @extend .panel-footer;
}
