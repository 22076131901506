fieldworker {
	flex: 1 1 auto;
	display: flex;
}

.fieldworker-app__container {
display: flex;
flex: 1 1 auto;
flex-direction: column;
z-index: 2;
}

.fieldworker-app__header {
	flex:0 0 auto;
}

.fieldworker-app__content {
	display: flex;
	flex: 1 1 auto;
}

.fieldworker-app__content > div.tab-content {
//	flex: 0 0 auto;
	background-color: $mid-grey;
}

.fieldworker-app__content > tmr-map {
	flex: 1 1 auto;
}

.fieldworker-app__content > .fieldworker-app__leftbar {
	flex: 0 0 auto;
	width: 400px;
	background-color: $mid-grey;
	overflow-y: auto;
	padding: 0 10px;

	display: flex;
	flex-direction: column;

	survey-week-select {
		flex: 0 0 auto; // It doesn't grow with the page
		padding-top: 10px;
		width: 100%;
		max-width: 400px;
	}
	fieldworker-todo, scheduled-event-work, work-summary {
		flex: 1 1 auto; // But everything else does
		padding-top: 10px;
		width: 100%;
	}

}

.fieldworker-app__filters {
	display: flex;
	align-items: baseline;
	flex-direction: row;
	justify-content: space-between;

    flex: 0 0 auto;

	.fieldworker-app__morefilters {
		width: 100%;
		max-width: 400px;
	}

	.fieldworker-summary__relevance-select {
		display: flex;
		align-items: baseline;

		label {
			flex: 0 0 auto;
			margin-right: 10px;
		}
		select {
			flex: 1 1 100%;
			width: 100%;
		}
	}

}

.fieldworker-app__content > .fieldworker-app__leftbar.without_map {
	width: 100%;
}

.fieldworker-app__header > h1 {
	flex: 1 1 auto;
	color: $white;
	line-height: 2.5;
	margin-left: 15px;
	margin-top:0px;
	align-self: center;

	@media (max-width:1280px) {
		font-size:2rem;
	}

}

.fieldworker-app__container > tab-set {
	background-color: $white;
}

.fieldworker-app__container > tab-set > ul {
	background-color: red;
}

.fieldworker-app__container .tab-set fieldworker-code {
  margin-left: 10px;
	padding: 0px 10px;
	border:1px solid $dark-grey;
}

.fieldworker-app__exit-button {
	margin: 5px 0px 5px 10px;
	padding: 5px 10px;
}

.fieldworker-app__home-button {
	font-size: 20px;
}

.fieldworker-app__header {
	display: flex;
	flex-direction: row;
	border-top: 10px solid $blue;
	// background-image: url("img/tmr_fieldworker_header.svg");
	background-repeat: no-repeat;
}

.fieldworker-app__header > h1, .fieldworker-app__header > access-household {
	flex: 1 auto;
}

.manager-app__header > access-household {
	align-self: center;
}

div.fieldworker-app__content > survey-week-select > select#surveyWeekSelect {
	width: 100%;
}

.resolved-event {
    text-decoration: line-through;
}
