tab-set {
	display: flex;
	flex:1 1 auto;
}

div.tab-content {
	flex: 1 1 auto;
	display: flex;
}

.tab-set {
	display:flex;
	flex: 1 1 auto;
	flex-direction: column;
}

.tab-set__tab-list {
display: flex;
border-top:0px;
border-bottom: 1px solid $dark-grey;

background-color: $mid-grey;
margin-bottom:0px;
height:5rem;

flex: 0 0 auto;

padding:0px;
-webkit-padding-start:0px;
}

.tab-set__tab-list > ul {
	display: flex;
}

.tab-set__tab-list > li {
	background-color: $mid-grey;
	display: flex;
	margin-left: 0px;
	border-left: 1px solid $dark-grey;
}

.tab-set__tab-list > li > a {

	display: flex;
	align-self: center;
	padding-left:10px;
	padding-right: 10px;
}

.active-temp {
	background-color: $blue;
	color:white;
	border-radius: 20px;
	margin:40px;
	padding:3px;

}

manager > div > tab-set > div > ul > li.ng-scope.active {
	background-color: #0053a5;
}


manager > div > tab-set > div > ul > li.ng-scope.active > a {
	color:white;
	text-decoration: none;
}

fieldworker > div > div > ul > li.active {
	background-color: #0053a5;
}

fieldworker > div > div > ul > li.active > a {
	color:white;
	text-decoration: none;
}

.text-muted-override  {
	span {
		color: inherit !important;
	}
}