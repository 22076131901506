//Household main view component

household {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	width: 100%;
}

.mandatory {
    color: red;
}

.household-main {
	display: flex;
	flex-direction: column;
	background-color: $light-grey;
	flex: 1 0 auto;
	align-self: center;
}

.household-main__header {
	flex: 0 0 auto;
	padding: 10px;
	text-align: center;
}

.household-main__details {
	flex: 0 1 auto;
}

.household-details__details__header {
	display: flex;
	border-bottom: 1px solid $dark-grey;
	border-top: 1px solid $dark-grey;
	padding:10px;
	padding-left:20px;
	background-color: $mid-grey;
	align-items: center;
}

.household-details__details__header__address {
	flex: 1 auto;
	text-align: left;
	padding-left:20px; 
}

.household-details__details__header__address h3 {
	color: $darkest-grey;
	font-size:3rem;
}

.household-details__details__header span {
	flex: 1 auto;
	background-color: $light-grey;
	border-radius: 10px;
	text-align: center;
	margin:10px;
	padding:10px;
	font-size: 2rem;
}

.household-details__details__header__text > label {
    font-weight: normal !important;
}

.household-main__tabs {
	flex: 1 auto;
	background-color: #fff;
}

.household-main__tabs > div > ul.nav.nav-tabs {
	background-color: #efefef;
}

.household-main__tabs > tab-set > div.tab-set {
	background-color: $mid-grey;
	padding-top:10px;
}

.household-main__tabs > tab-set > div.tab-set > div.tab-content {
	background: #f0f0f0;
	flex-direction: column;
}

div.household-main__tabs > div > div > div.tab-pane {
	width: 100%;
}

.household-main__tmr-back-btn {
	margin-right: 15px;
}

.dnc-buttons {
display: flex;
justify-content: space-between;
}

#doNotContact {
	align-self: flex-end;
}

.donotcontact__container {
	padding:10px;
	border: 1px solid #aaa;
	border-radius: 10px;
}

.do-not-contact-danger {
	background-color: #ff5454;
	color:white;
	border:1px solid red;
}

.force-select {
  -webkit-user-select: all;  /* Chrome 49+ */
  -moz-user-select: all;     /* Firefox 43+ */
  -ms-user-select: all;      /* No support yet */
  user-select: all;          /* Likely future */
}

.household-map__content > tmr-map {
	flex: 0 0 100%;
}

household-map {
	width:100%;
	display:flex;
	flex-direction: column;
}

// Fixes map not rendering fullscreen on household details page

div.household-app__container {
	display: flex;
	flex:1 1 auto;
	flex-direction: column;
}

div.household-map__content, div.household-app__content {
display: flex;
flex: 1 1 auto;
}

div.household-app__content {
	background-color: #efefef;
}

.household-app__home-button {
	font-size: 20px;
}

.well {
	margin:0px;
}

.household-details_no-content-container {
	height:50%;
	position: relative;
}

.household-details_no-content-message {
	border: 1px solid #aaa;
	display: block;
	margin: 0;
	padding:50px;
	position: absolute;
	top: 50%;
	left:50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	border-radius:10px;
}

.household-notes-table thead tr th {
	text-align: left;
}

// Makes notes fullscreen

household-notes {
	flex: 1 1 auto;
}

household-notes table thead .ng-table-sort-header th:nth-child(5),
household-notes table tbody tr td:nth-child(5) {
	width: 300%;
}

// Makes events fullscreen
household-event {
	flex: 1 1 auto;
}

contact-event-list > table > thead {
    display: none;
}

household-event table tbody tr td:nth-child(5) {
	width: 300%;
}
.household-details__details__header > button {
	margin-right:10px;
}

.household-details__details__header > h3 {
	text-align:center;
}

household-main {
	display: flex;
	flex-direction: column;
	flex:1 1 auto;
}

div.household-main {
	flex:1 1 100%;
}

.inline-datetime-picker > logicly-datetimepicker > ng-form {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
  height: 10rem;
}

.inline-datetime-picker > logicly-datetimepicker > ng-form > .datetimepicker-wrapper {
	padding: 5px;
	box-sizing: border-box;
	flex: 1 0 30%;
}

.inline-datetime-picker > logicly-datetimepicker > ng-form > .datetimepicker-wrapper > input[name="datepicker"] {
    margin-top: 3.25rem;
}

.inline-datetime-picker > logicly-datetimepicker > ng-form > .datetimepicker-wrapper[name="datepicker"] {
    max-width: 250px;
}

.inline-datetime-picker > logicly-datetimepicker > ng-form > .datetimepicker-wrapper[name="timepicker"] {
    padding-top: 4px;
}

.inline-datetime-picker > logicly-datetimepicker > ng-form > ng-transclude {
    margin-top: 3.25rem;
    padding-top: 5px;
}

.date-time-label {
	box-sizing: border-box;
	flex: 0 1 100%;
}
