.fieldworker-select {
	border-bottom: 1px solid $mid-grey;
	background-color: $light-grey;
	padding:10px;
	height:5em;
	select {
		min-width: 150px;
	}
}

fieldworkers-list > table > thead {
	background-color: $light-grey;
}

.scheduled-event-form__survey-worker-select {
	.fieldworker-select {
		border-bottom: none;
		background-color: $white;
		padding: 0;
		height: 4em;
	}
}
