manager {
	display: flex;
	flex: 1 1 auto;
}

manager > div {
	display: flex;
	flex-direction: column;
}

.manager-app {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	z-index: 2;
	// 100vh height is only viewheight and if a user selects 25 items per page
	// it cross the page view height and style no longer applies in that case
	// so keep it 100%
	height:100%;
}

.manager_app__ui_view,
.manager_app__auth,
.manager_app__project {
	display:flex;
	flex: 1 1 auto;
	flex-direction: column;
}

.manager-app__container {
	flex: 1 0 100%;
	display: flex;
}

.tab-set > ul {
	display: flex;
	flex-direction: row;
}



.manager-app__header {
	display: flex;
	flex-direction: row;
	flex: 0 0 auto;
	border-top: 10px solid $blue;
	// background-image: url("img/tmr_manager_header.svg");
	background-repeat: no-repeat;
}

.manager-app__header > h1 {
	flex: 1 1 auto;
	color: $white;
	line-height: 2.5;
	margin-left: 15px;
	margin-top:0px;
	align-self: center;

	@media (max-width:1280px) {
		font-size:2rem;
	}
}


.logged-in {
	text-align: right;
	align-self: center;
	padding-right: 5px;
	display: flex;
	flex: 1 1 auto;
	justify-content: flex-end;
}

.logged-in > span {
	color: $white;
	padding: 10px;
	background-color: $dark-blue;
	border-radius: 3px 0px 0px 3px;
}

