// These get included last, so you can override things.

// The default margin-bottom for .form-group is 15px.
// This is what makes the bottom row of timepicker spinners hang down weirdly.
// 4px seems to even it up (maybe 5?)
.uib-timepicker .form-group {
  margin-bottom: 4px;
}

// Hide the "Clear" button in the date picker popup. We don't want that.
.uib-datepicker-popup .uib-clear {
  visibility: hidden;
}

// update border colour for greyed out event accessibility
.table-bordered {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid #7A7A7A;
        border-style: solid none;
      }
    }
  }
}

// update btn-danger colour for accessibility
.btn-danger{
  background-color: #D4403A;
}