//Household main view component

manager {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	width: 100%;
}

.mandatory {
    color: red;
}

.manager-main {
	display: flex;
	flex-direction: column;
	background-color: $light-grey;
	flex: 1 0 auto;
	align-self: center;
	width: 100%;
}

.manager-main__header {
	flex: 0 0 auto;
	padding: 10px;
	text-align: center;
}

.manager-main__details {
	flex: 0 1 auto;
}

.manager-main__tabs {
	background-color: #fff;
}

.manager-main__tabs > div > ul.nav.nav-tabs {
	background-color: #efefef;
}

.manager-main__tabs > tab-set > div.tab-set {
	background-color: $mid-grey;
	padding-top:10px;
}

.manager-main__tabs > tab-set > div.tab-set > div.tab-content {
	background: #f0f0f0;
	flex-direction: column;
}

div.manager-main__tabs > div > div > div.tab-pane {
	width: 100%;
}

manager-main {
	display: flex;
	flex-direction: column;
	flex:1 1 auto;
}

div.manager-main {
	flex:1 1 100%;
}

div.manager-main > h4 {
	padding: 5px;
}

div.manager-main_tabset_hdg > h4 {
    display: inline-block;
    margin: 10px;
}

/* right-align the 'future work' column
 * on the 'current users' tab:
 */
table.ng-table thead tr th.future-work,
table.ng-table tbody tr td.future-work
{
    text-align: right;
    padding-right: 20px;
}

button#inviteUserBtn {
    float: right;
    margin: 3px;
}

div.user-invite__form {
	display: flex;
	flex-direction: row;
	flex:1 1 auto;
}
div.user-invite__details div.form__container div:not(:first-child) {
	margin-left: 10px;
}

div.user-invite__button {
	padding-top: 24px;
}

.zero-height {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.4s ease-out;
}
.form-error {
	font-size: 80%;
	color: #d2322d;
}
