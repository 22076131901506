//Overall Page container for development to hold app and admin bar - Can be deleted when admin bar removed.

.page {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	background-color: $dark-blue;
	z-index: 2;
}

.page__admin-bar {
	background-color: black;
	color:white;
	width:100%;

	flex: 0 auto;
	padding-left: 25px;
	display: block;
	align-items: center;
}

.page__admin-bar > top-nav > ul {
	display: inline-block;
}

.page__admin-bar > top-nav > ul > li {
	list-style-type: none;
	float: left;
}

.top-nav-btn {
	height: 2.2em;
	margin: 10px;
}

.page__footer {
	flex:0 0 auto;
	background-color: $dark-blue;
	width: 100%;
	height:2rem;
	color:white;
}
