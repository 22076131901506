@font-face {
  font-family: 'tmr-icons';
  src:  url('../fonts/tmr-icons.eot?9clod3');
  src:  url('../fonts/tmr-icons.eot?9clod3#iefix') format('embedded-opentype'),
    url('../fonts/tmr-icons.woff2?9clod3') format('woff2'),
    url('../fonts/tmr-icons.ttf?9clod3') format('truetype'),
    url('../fonts/tmr-icons.woff?9clod3') format('woff'),
    url('../fonts/tmr-icons.svg?9clod3#tmr-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tmr-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tmr-manager:before {
  content: "\e900";
}
.icon-tmr-contact:before {
  content: "\e901";
}
.icon-tmr-level-up:before {
  content: "\e902";
}
.icon-tmr-level-down:before {
  content: "\e903";
}
