.household-list-all__container {
    display:flex;
    flex:1 1 auto;
    flex-direction:column;
    width: 100vw;
}

.household-status {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-items: stretch;
	overflow-x:scroll;
}

household-list-all {
	display: flex;
	background-color: $light-grey;
	flex: 1 1 auto;
}

.household-list-all-table {
    height: 80vh;
}

.household-list-all-table__col-large {
    width: 30rem;
}

.household-list-all-table__col-small {
    width: 12rem;
}

table.household-list-all-table > tbody > tr > td:not(.household-list-all-table__col-large, .household-list-all-table__col-small) {
	width: 20rem;
}

table.household-list-all-table > thead > tr > th:not(.household-list-all-table__col-large, .household-list-all-table__col-small) {
    width: 20rem;
}

/* Targets parameters bar */
div.household-status > div.ng-scope.ng-isolate-scope {
	position: sticky;
	left:0;
}
