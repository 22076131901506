tmr-map {
  display: flex;
  flex-grow: 1;
}

.tmr-map {
  width: auto;
  flex:1 1 auto;
  display: flex;
}

.tmr-map .tmr-map-no-households {
  position:absolute;
  width: 33%;
  margin-top: 6em;
  text-align: center;
}
.tmr-map .tmr-map-no-households > div {
  background: white;
  padding: 12px 6px;
  margin: 0 auto;
  width: 66%;
}

.info-window {
  width: 100%;
  text-align: center;
}
/* See https://github.com/allenhwkim/angularjs-google-maps/issues/437 */
ng-map {
  flex:1 1 auto;
}
