household-list {
background-color: $white;
}

.household-list-all__download-link {
    position: sticky;
    color:red;
}

// Note: this overrides the default BG colour of a table, which is transparent.
household-list > table {
	background-color: $white;
}

//Table related styling
tbody, thead, tfoot {
    display: flex;
    flex-direction: column;
    flex-grow:0;
    flex-shrink:0;
}

tbody.overflowable, thead.overflowable, tfoot.overflowable {
    flex-grow: 1;
    flex-shrink: 1;
}

tr {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 0;
  }

td, th {
    display: block;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
		width: 100%;
}

input.input-filter {
    font-weight: normal;
}

household-list {
  justify-content: center;
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
}

household-list > table {
	margin-bottom:0;flex:1 auto;display:flex;flex-direction:column;overflow:auto;
}

.household-list-all__download-link {
    padding:5px;
    width: 100%;
    text-align: left;
}

.household-list-all__download-link__button-label {
	margin-left: 1rem;
}

assign-events table .text-left {
    text-align: left;
}

household-list table .text-left {
    text-align: left;
}

household-list-all table .text-left {
    text-align: left;
}

household-list-all table .text-right {
    text-align: right;
}
th.date-error {
    div {
        input {
            border: 1px solid #ffc107;
            box-shadow: inset 0px 0px 0px 1px #ffc107;
            &:focus {
                border: 1px solid #ffc107;
                box-shadow: inset 0px 0px 0px 1px #ffc107;
            }
        }
    }
}

.household_filter {
    padding: 6px 12px;
    display: grid;
    grid-template-columns: 1fr 80px;
    grid-template-areas: "results filter_button"
                         "filter_form filter_form";
    justify-content: center;
    align-items: center;
    width: 100%;

    .results {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        .result {
            padding: 6px;
            border-radius: 4px;
            border: 1px solid #aaa;
            background: white;
        }
    }

    .filter_button {
        grid-area: filter_button;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        .btn-inline {
            padding: 6px;
        };
    }

    .filter_form {
        grid-area: filter_form;
        border: 1px solid #aaa;
        border-radius: 4px;
        background: white;
        margin-top: 12px;

        .heading {
            border-bottom: 1px solid #aaa;
            display: flex;
            height: 40px;
            .outer_tabs{
                margin-bottom: 0px;
                display: flex;
                align-items: center;
                border-bottom: 2px solid #337ab7;
                padding: 0px;
                li {
                    list-style: none;
                    a {
                        padding: 8px 18px;
                    }
                }
            }
        }
        .body {
            .shortcuts {
                padding: 12px;
            }
            .content {
                padding: 12px;
                padding-bottom: 24px;
                display: flex;
                grid-gap: 12px;

                .form-control {
                    width: auto;
                }
            }

        }
    }
}
//.household-list__table__token {flex:0 0 20%;}
//.household-list__table__address {flex:3 0 10%;}
//.household-list__table__status {flex:0 0 10%;}
//.household-list__table__assign {flex:0 0 10%;}
//.household-list__table__workerid {flex:0 0 10%;}
