.tab-set__tab-list .active {
	display: flex;
	background-color: #0053a5;
}

.tab-set__tab-list .active a {
	color: #fff;
}

.mandatory {
	color: red;
}

.text-bold {
	font-weight: bold;
}
