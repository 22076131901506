//Overrides for UIB-Timepicker
/*
td.uib-separator {
	padding: 10px;
	line-height: 1rem;
}

div.date-time__contact-date > p {
	margin-top: 33px;
	margin-left: 10px;
	margin-right: 10px;
}

#contactTimepicker > table > tbody > tr:nth-child(1) > td:nth-child(2), #contactTimepicker > table > tbody > tr:nth-child(3) > td.uib-decrement.minutes > a {
	margin-left: -35px;
}

#contactTimepicker > table > tbody > tr:nth-child(2) {height:36px}
*/

table.uib-timepicker {
	background: none;
}
