// These classes are involved with the loading spinner
#loader__loading {
  display: none;
  align-self: center;
  justify-content: center;

  &.loader--is-loading {
    display: block;
    color: white;
    align-self: center;
    justify-content: center;

    // This animation business hides the loading spinner for animation-delay
    // seconds, and then fades it in over animation-duration seconds.
    animation-name: loading_delayed_display;
    animation-duration: 1s;
    animation-delay: 2s;
    // This makes the loading_delayed_display.from state apply before the
    // animation, and the loading_delayed_display.to state apply after the
    // animation.
    animation-fill-mode: both;
  }
}

.content_loading_container {
  background: black;
  opacity: 0;
  animation-name: content_loading_delay;
  animation-delay: 3s;
  animation-fill-mode: both;
  z-index: 3 !important;

}
.content_loading {
  display: block;
  color: black;
  align-self: center;
  justify-content: center;
}

@keyframes loading_delayed_display {
  from { opacity: 0.0; }
  to   { opacity: 1.0; }
}

@keyframes content_loading_delay {
  from { opacity: 0.0; }
  to   { opacity: 0.4; }
}

#loader__success {
  &.loader--is-loading {
    display: none;
  }

  &.loader--is-error {
    display: none;
  }
}

#loader__error {
  display: none;

  &.loader--is-error {
    display: block;
    color: white;
  }
}

.loader__container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}
