.project-select-main {
	display: flex;
	flex-direction: column;
	background-color: $light-grey;
	flex: 1 0 auto;
	align-self: center;
	width: 100%;
}

.project-select-main table .text-left {
    text-align: left;
}

.project-select-main table > thead > tr > th:first-child,
.project-select-main table > tbody > tr > td:first-child,
.project-select-main table > thead > tr > th:nth-child(2),
.project-select-main table > tbody > tr > td:nth-child(2) {
    width: 50%;
}

.project-select-main table > thead > tr > th:last-child,
.project-select-main table > tbody > tr > td:last-child {
    width: 80%
}

.project-select-main__project-link {
    width: 100%;
    text-align: left;
}
.project-select-main__project-link:focus {
    outline: 0 !important;
}

.archive-info-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.archive-error-msg {
    color: red;
}

.archive-loading-wheel {
    margin-top: 5px;
    width: 30px;
    height: 30px;
}

.active-project-status {
    margin-top: 10px;
    font-size: 15px;
    color: green;
}

.retired-project-status {
    margin-top: 10px;
    font-size: 15px;
    color: red;
}

.project-logout__btn {
    margin: 10px auto;
    width: 20em;
    text-align: center;
}

.project-selector__warning_div {
    width: 50%;
    margin: 20px auto;
}

.alert {
    background-color: #f5f5f5;
    border-color: #ddd;
    text-align:center;
}

.project-selector__warning {
    margin: auto;
    width: 20em;
    color: #333;
}
