assign-events {
	flex:1 1 auto;
	display: flex;
	flex-direction: column;
}

events-list {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
}

events-list > table {
	display: flex;
	flex:1 0 auto;
	flex-direction: column;
}

events-list > div {
	margin-left: 10px;
	margin-right: 10px;
}

.assign-workloads {
	background-color: $mid-grey;
}
