.scheduled-event {
	flex-direction: column;
}

.scheduled-event__content {
	border:1px solid $dark-grey;
    border-radius: 10px;
	margin:10px;
}

.scheduled-event__highlight-row {
    background-color: $light-blue;
}

.scheduled-event__row {
    background-color: $light-grey;
}

.scheduled-event-table-container {
    border: 1px solid $dark-grey;
}

.scheduled-event-btn-container {
    margin:10px;
}

.delete-scheduled-event-btn {
    margin-right:20px;
}
