//$tmr-theme-01: #003c69; //TMR DARK BLUE
//$tmr-theme-02: #fd8204; //TMR ORANGE
//$tmr-theme-03: #1675a9; //TMR BRIGHT BLUE
//$tmr-theme-04: #80bd01; //TMR GREEN
//$tmr-theme-05: #486e90; //TMR GREY BLUE
//$tmr-theme-06: #f0f0f0; //LIGHT GREY
//$tmr-theme-07: #cacaca; //SLIGHTLY DARKER GREY


// TMR THEME COLOURS

//$tmr-theme-colour-01: rgb(173, 173, 173); // MEDIUM GREY
//$tmr-theme-colour-02: rgb(179, 179, 179);
//$tmr-theme-colour-03: rgb(185, 185, 185);
//$tmr-theme-colour-05: rgb(55, 55, 55); // BASE COLOUR


$light-grey: #efefef;
$mid-grey: #dbdada;
$midder-grey: #cccccc;
$dark-grey: #aaaaaa;
$darkest-grey: #4f4f4f;

$light-blue: #99ccff;
$blue: #0053a5;
$dark-blue: #003c69;
$white: #ffffff;

$table-bg: #ffffff;
