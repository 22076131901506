
.events-list__event-filter {
	margin:10px;
}

.events-list__event-filter > label { display:inline-flex; flex: 1 0 auto; align-self: center; font-weight:700;}

.events-list__clear-button {
	display: inline-flex;
	flex:0 1 auto;
  align-self: center;
	margin-bottom: 10px;

}

.events-list__filter-date-from {
	display: inline-flex;
	flex:1 1 auto;
	padding-right: 35px;
  align-items: center;
}

div.events-list__filter-date-from > label {
	margin-right: 10px;
}

event-list table .text-left {
    text-align: left;
}
