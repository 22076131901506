html, body {
	height: 100%; width: 100%; margin: 0;
}

body {
	display: flex;
	flex-direction: column;
	align-items: stretch;

	font-family: 'Open Sans', sans-serif;
}

tmr-map, fieldworkers-list, household-list {
	flex-grow: 1;
}

h2 {
	font-weight: 100;
	margin: 0px;
	font-size: 2rem;
}

h3 {
	margin: 0px;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Nunito', sans-serif;
}

table {
	background-color: $white;
	tr {
		td.truncate-text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
