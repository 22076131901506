reminder-logs {
    background-color: $light-grey;
}

.reminder-logs__container {
    display:flex;
    flex:1 1 auto;
    flex-direction:column;
    width: 100vw;
}

.reminder-logs__table {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: stretch;
    overflow-x:scroll;
}

.reminder-logs__filters {
    background-color: $mid-grey;
}
