date-time {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

date-time > div {
	flex: 1 1 50%;
}

date-time > div > p.input-group {
	margin-top:2.4rem;
}

div.form__threequarter > label:nth-child(1) {
	margin-top:1rem;
}

div.form__threequarter > label:nth-child(3) {
	margin-top: 1rem;
}
