.workload-list {
	display:block;
	width: 100%;
	padding: 0 0 0 10px;
	overflow-y: scroll;
	height: 90%;

}

.workload-list__list {
	padding:5px 0;
}

.workload-list__list > li {
	margin: 5px 0;
	padding-left: 0;
}

li.workload-list__entry {
	display: flex!important;
	flex-direction: row;
	flex-wrap: nowrap;
	border: 0;
	border-radius: 0;
	padding: 0 8px 0 3px;
	outline: none;
}

.workload-list__entry a {
	color: $dark-grey;
}

.workload-list__workload {
	display: block;
	flex: 1 50%;
	border-radius: 5px 0 0 5px;
	background: $mid-grey;
	padding-left: 5px;
	color: $darkest-grey;
	line-height: 24px;
}

.workload-list__counter {
	display: flex;
	flex: 1 auto;
	margin-right: 5px;
	outline: none;
}

.workload-list__counter > button {
	width:100%;
	border-radius: 0px 5px 5px 0px;
	border:none;
	margin:none;
	background-color: $dark-grey;
	outline: none;
}




li.workload-list__entry.active .workload-list__workload
{
	background-color: $light-blue;
}

li.workload-list__entry.active .workload-list__counter > button {
	background-color: $blue;
	color: white;
}




.workload-list__settings, .workload-list__report {
	display: inline-flex;
	line-height: 24px;

	//border-radius: 5px;
	//border: 1px solid white;
	//&:hover {
	//	border-color: $mid-grey;
	//}
}

.workload-list__settings a, .workload-list__report a {
	text-decoration: none;
	line-height: 22px;

	display: inline-block;
	padding: 0 4px;
	margin: 0 4px;

	background: white;
	color: $dark-grey;

	outline: none;

}

.workload-list__pipe {
	color: $mid-grey;
}

.workload-list__total {
	padding: 5px 0px;
	background: #eee;
	margin-left: -10px;
	display: flex;
	padding-bottom:4px;
	.workload-list__counter {
		flex: 1 auto;
			button {
				min-height: 25px;
		}
	}
}
