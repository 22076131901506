.resolved-log__content, .resolved-log__event-info {
	border:1px solid $dark-grey;
    border-radius: 10px;
	margin:15px;
}

.resolved-log__event-info {
	box-sizing: border-box;
	padding-left:10px;
    width: 50%;
}

.resolved-log__title {
	box-sizing: border-box;
	padding-left:15px;
	flex: 0 1 100%;
}
