.topnav__fieldworker-select > fieldworker-select > div {
	background-color: #dbdada;
}

.topnav__fieldworker-select {
	border:none;
}

.topnav__fieldworker-select > fieldworker-select > div.fieldworker-select {
	border-bottom: 1px solid #aaa;
	height: 5rem;
}

.topnav__fieldworker-select > button {
	align-self: center;
	flex:1 1 auto;
	margin-right: 10px;
	width:50px;
}
