
.profile {
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: center;
    grid-column-gap: 5px;
    .dropdown {
        min-width: 125px;
        height: 40px;
        display: flex;
        cursor: pointer;
        button {
            color:$white;
            background-color: $blue;
            border:none;
            border-radius: 3px;
            transition: 0.1s ease-in-out;
            &:hover {
                cursor: pointer;
            }
        }
    
        .dropdown-menu {
            color: $blue;
            top: 110%;
            left: 0;
            min-width: 130px;
            background-color: gainsboro;
            border-radius: 3px;
            border-top: 1px solid white;
            a {
                cursor: pointer;
                transition: 0.1s ease-in-out;
                padding: 4px 15px;
                &:hover {
                    background-color: $blue;
                    color: white;
                }
            }
        }
    }
}